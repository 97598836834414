import GlobalStyle from "@assets/styles/globals";
import { CheckTypeMobile } from "@pages/check-type-mobile";
import { Home } from "@pages/home";

import React from "react";
import { Route, Routes } from "react-router-dom";
import "reactjs-popup/dist/index.css";

import "hamburgers/dist/hamburgers.css";
import "swiper/css";
import "swiper/css/pagination";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/check-type-mobile" element={<CheckTypeMobile />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <GlobalStyle />
        </>
    );
}

export default App;
